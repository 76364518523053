import React, { useState, useEffect } from 'react';
import { Formik, FormikValues } from 'formik';

import DialogContent from '@material-ui/core/DialogContent';

import { alertSchema } from '../../schemas';

import { useStyles } from './Themable.hooks';
import { useEvents, useLanguage, useProviders } from './Connectable.hooks';

import {
    fetchParamsDefinitions,
    fetchAlertProviders,
} from '../../state/app/alerts';
import { customAction } from '../../state/_actions';
import { IAlertForm } from '../../state/ui/forms';
import { IPreviewAction } from '../../state/ui/discovery/types';
import { TAlertDialog } from '../../state/app/alertsPage';

import { prepareSubmitAction } from './_utils/prepareSubmitData';

import TranslationHelper from '../../helpers/TranslationHelper';

import { useAppDispatch } from '../../hooks';

import AlertForm from './components/AlertForm';
import DialogTitle from '../../components/dialogs/DialogTitle';
import LoadingSpinner from '../../components/loadingSpinner/LoadingSpinner';

import { useGridFilters } from '../../pages/discovery/selectors/index.hooks';
import { IAlertSettingWithCondition } from '../../services/alerts';
import {
    initializeFormData,
    prepareConditionData,
    prepareRecipients,
    prepareSubjectData,
} from './_utils/prepareFormData';
import { fetchConditionsData } from './_utils/fetchConditionsData';

interface IOwnProps {
    close?: () => void;
    dialog: TAlertDialog | null;
    onSubmit?: (filters?: { [key: string]: string | number | boolean }) => void;
}

export interface IDialogProp {
    mode: string;
    elementId: string | null;
}

const AlertSettingDialog = ({ close, dialog, onSubmit }: IOwnProps) => {
    const userLanguage = useLanguage();
    const events = useEvents();
    const classes = useStyles();
    const filters = useGridFilters();
    const providers = useProviders();
    const dispatch = useAppDispatch();
    const initialForm = initializeFormData(userLanguage);

    const [alertLoaded, setAlertLoaded] = useState<boolean>(false);
    const [formData, setFormData] = useState<IAlertForm>(initialForm);
    const title =
        dialog?.mode === 'add'
            ? TranslationHelper.translate('Add alert')
            : TranslationHelper.translate('Edit alert');

    useEffect(() => {
        const action = dialog?.dialogAction;

        const initializeForm = async () => {
            await dispatch(fetchAlertProviders());

            if (dialog?.mode === 'add') {
                initializeAddForm();
            }
            if (dialog?.mode === 'edit') {
                action && initializeEditForm(action);
            }
        };

        initializeForm();
    }, [dispatch, dialog]);

    if (!dialog?.mode) {
        return null;
    }

    const formValueToString = (value?: number) => {
        if (!value || value === -1) {
            return '';
        } else {
            return value.toString();
        }
    };

    const initializeAddForm = () => {
        if (dialog.mode === 'add') {
            if (dialog?.elementId) {
                dispatch(
                    fetchParamsDefinitions(dialog.elementId, () => {
                        setAlertLoaded(true);
                    })
                );
            } else {
                setAlertLoaded(true);
            }
        }
    };

    const initializeEditForm = (action: IPreviewAction) => {
        if (
            dialog.dialogAction &&
            dialog.elementCollectionName &&
            dialog.mode === 'edit'
        ) {
            const getAction: IPreviewAction = {
                api: action?.api,
                label: 'GET',
                method: 'GET',
                params: { id: dialog.elementCollectionName || '' },
            };
            dispatch(
                customAction(
                    getAction,
                    undefined,
                    async (setting: IAlertSettingWithCondition) => {
                        const subject = await prepareSubjectData(
                            setting.objectId,
                            setting.groupIds
                        );
                        await fetchConditionsData(
                            setting.condition,
                            dispatch,
                            setting.objectId
                        );
                        const recipients = prepareRecipients(
                            setting.recipients,
                            providers,
                            userLanguage
                        );
                        const condition = prepareConditionData(
                            setting.condition
                        );
                        setFormData({
                            recipients,
                            condition,
                            alertSubject: setting.objectId ? 'object' : 'group',
                            objectId: setting.objectId || -1,
                            name: setting.name,
                            ...subject,
                        });
                        setAlertLoaded(true);
                    }
                )
            );
        }
    };

    const resetBlankForm = () => {
        setFormData(initialForm);
    };

    const handleCloseClick = (resetFormik?: () => void) => {
        setAlertLoaded(false);
        resetBlankForm();
        resetFormik && resetFormik();
        close && close();
    };

    const handleSubmit = async (
        data: FormikValues,
        resetFormik: () => void
    ) => {
        setAlertLoaded(false);
        const { actionWithParams, action, alertData } = prepareSubmitAction(
            data,
            dialog,
            events
        );
        await dispatch(
            customAction(
                (actionWithParams || action) as IPreviewAction,
                alertData,
                () => handleCloseClick(),
                () => handleCloseClick()
            )
        );
        onSubmit && onSubmit(filters);
        resetFormik();
        close && close();
    };

    return (
        <Formik
            enableReinitialize={true}
            initialValues={formData}
            onSubmit={(values, { resetForm }) =>
                handleSubmit(values, resetForm)
            }
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={alertSchema}
        >
            {({ errors, values, setFieldValue, resetForm, submitForm }) => (
                <>
                    <DialogTitle
                        title={title}
                        close={() => handleCloseClick(resetForm)}
                        save={submitForm}
                    />
                    <DialogContent className={classes.dialogContent}>
                        {alertLoaded ? (
                            <AlertForm
                                errors={errors}
                                values={values}
                                setFieldValue={setFieldValue}
                                dialog={{
                                    mode: dialog.mode,
                                    elementId: formValueToString(
                                        values.objectId
                                    ),
                                }}
                                displaySubjectFields={!dialog.elementId}
                            />
                        ) : (
                            <LoadingSpinner />
                        )}
                    </DialogContent>
                </>
            )}
        </Formik>
    );
};

export default AlertSettingDialog;
