import apiClient from '../common/apiClient';
import { IDeviceDetails } from './model';
import { UAParser } from 'ua-parser-js';

const DEVICE_UUID_KEY = 'deviceUuid';

export const getDeviceUuid = (): string => {
    const storageUuid = localStorage.getItem(DEVICE_UUID_KEY);
    if (storageUuid) {
        return storageUuid;
    }
    const randomUuid = crypto.randomUUID();
    localStorage.setItem(DEVICE_UUID_KEY, randomUuid);
    return randomUuid;
};

export const getDeviceDetails = async (): Promise<IDeviceDetails> => {
    const { browser, cpu, os } = UAParser(navigator.userAgent);

    const response = await apiClient.sendRequest<{ ip: string }>(
        'https://api.ipify.org?format=json'
    );

    return {
        browserName: browser.name || '',
        os: os.name || '',
        cpu: `${cpu.architecture || ''} ${navigator.hardwareConcurrency}x`,
        ip: response.ip,
    };
};
