import { omit } from 'lodash';
import * as moment from 'moment-timezone';
import { vsprintf } from 'sprintf-js';
import URI from 'urijs';
import AppConfig from '../../../constants/AppConfig';
import UrlConst from '../../../constants/UrlConst';
import TimeFormatter from '../../../helpers/TimeFormatter';
import {
    IPreviewAction,
    ISourceSetAction,
} from '../../../state/ui/discovery/types';
import { makeUrl } from '../../common/utils';

export const makeChartUrl = (suffix: string) => {
    const url = new URI(suffix);
    url._parts.query = url._parts.query.replace(/\+/g, '%2B');
    return makeUrl(url, {});
};
export const makeMonitoredObjectsUrl = (suffix: string, params = {}) => {
    const url = new URI(
        AppConfig.instance.getUrl(UrlConst.MONITORED_OBJECTS_URL) + suffix
    );
    return makeUrl(url, params);
};
export const makeSourceSetsUrl = (suffix: string, params = {}) => {
    const url = new URI(
        AppConfig.instance.getUrl(UrlConst.SOURCE_SETS_URL) + suffix
    );
    return makeUrl(url, params);
};
export const makeReportSetsUrl = (suffix: string, params = {}) => {
    const url = new URI(
        AppConfig.instance.getUrl(UrlConst.REPORT_SETS_URL) + suffix
    );
    return makeUrl(url, params);
};

export const makeReportSectionsUrl = (suffix: string, params = {}) => {
    const url = new URI(
        AppConfig.instance.getUrl(UrlConst.REPORT_SECTIONS_URL) + suffix
    );
    return makeUrl(url, params);
};

export interface ITasksUrlParams {
    from: string;
    to: string;
    onlyNotAssigned?: boolean;
    includeNotPlanned?: boolean;
}

export interface IRoutesUrlParams {
    from: string;
    to: string;
}

export const makeSearchUrl = (searchPhrase: string) =>
    makeMonitoredObjectsUrl(`/current`, { name: searchPhrase });
export const makeMonitoredObjectUrl = (monitoredId: string) =>
    makeMonitoredObjectsUrl(`/current/${monitoredId}`);
export const makeLastStateUrl = (
    monitoredId: string,
    params?: { hiddenParams: boolean }
) => makeSourceSetsUrl(`/last-states/${monitoredId}`, params);
export const makeRoutesUrl = (params?: IRoutesUrlParams) =>
    makeSourceSetsUrl(`/routes`, params);
export const makeTasksUrl = (params?: ITasksUrlParams) =>
    makeSourceSetsUrl(`/tasks`, params);

export const makeTaskUrl = (id: string) => makeSourceSetsUrl(`/tasks/${id}`);
export const makeLastStatesUrl = (params?: { hiddenParams: boolean }) =>
    makeSourceSetsUrl(`/last-states?orderBy=date`, params);

export const makeAlertSettingsUrl = () =>
    makeSourceSetsUrl(`/alerts/settings`, {});

export const makeLocationsUrl = (params?: { name?: string; limit?: number }) =>
    makeSourceSetsUrl(`/locations`, params);
export const makeRecognizedNumbersUrl = (params: string[]) =>
    makeReportSectionsUrl(`/recognized-numbers`, { params });
export const makeReportSetUrl = (
    monitoredId: string,
    from: string,
    to: string
) =>
    makeReportSetsUrl(``, {
        monitoredId,
        from,
        to,
    });
export const makeSourceSetUrl = (sourceSetAction: ISourceSetAction) => {
    const { api, params } = sourceSetAction;
    return makeUrl(new URI(api), params);
};
export const makeGenericUrl = (previewAction: IPreviewAction) => {
    const { api, params } = previewAction;

    const foundParams = (api.match(/{([^}]+)}/gm) || []).map((item) =>
        item.slice(1, -1)
    );

    const newApi = Object.entries(params || {}).reduce(
        (result, [key, value]) => result.replace(`{${key}}`, value as string),
        api
    );
    return makeUrl(new URI(newApi), omit(params, foundParams));
};
export const makeTrailUrl = (
    id: string,
    from = TimeFormatter.getISOString(moment('2017-10-16')),
    to = TimeFormatter.getISOString(moment('2017-10-20'))
) => {
    const url = makeMonitoredObjectsUrl(`/archives/map`);
    url.addQuery('connectAllPoints', false);
    url.addQuery('preventCache', Date.now());
    const index = 0;
    const archiveString = 'archive[%s][%s]';

    url.addQuery(vsprintf(archiveString, [index, 'object']), id);
    url.addQuery(vsprintf(archiveString, [index, 'from']), from);
    url.addQuery(vsprintf(archiveString, [index, 'to']), to);

    return url;
};
export const makeDataStatusUrl = (ids: string[], from: string, to: string) => {
    return makeUrl(new URI(`/rest/api/monitored/archives/events/status`), {
        ids,
        from,
        to,
    });
};

export interface IMovieUrlArgs {
    sliderTimeRange: number[];
    eventId?: string;
    paramName?: string;
    monitoredId?: string;
    eventDate?: string;
    anonymize?: boolean;
    hls?: boolean;
    labels?: boolean;
    model?: string;
}

export interface ILinkVideoArgs {
    timestamp: string;
    monitoredId: string;
    selectedCamera: string;
    rangeMinus?: number;
    rangePlus?: number;
    anonymize?: boolean;
    hls?: boolean;
    labels?: boolean;
    model?: string;
}

export const makeVideoDownloadUrl = ({
    sliderTimeRange,
    eventId,
    paramName,
    monitoredId,
    eventDate,
    anonymize,
    hls,
    labels,
    model,
}: IMovieUrlArgs) => {
    return makeUrl(new URI(`/rest/api/monitored/movies/merge`), {
        rangeMinus: Math.abs(sliderTimeRange[0]),
        rangePlus: sliderTimeRange[1],
        eventId,
        paramName,
        monitoredId,
        eventDate,
        anonymize,
        hls,
        labels,
        model,
    });
};

export const makeLinkVideoURL = ({
    timestamp,
    monitoredId,
    selectedCamera,
    rangeMinus,
    rangePlus,
    anonymize,
    hls,
    labels,
    model,
}: ILinkVideoArgs) => {
    return makeUrl(new URI(`${window.location.origin}/player`), {
        timestamp,
        monitoredId,
        selectedCamera,
        rangeMinus:
            rangeMinus || rangeMinus === 0 ? Math.abs(rangeMinus) : undefined,
        rangePlus,
        anonymize,
        hls,
        labels,
        model,
    });
};
