import React, { useEffect, useState } from 'react';
import { FieldArray, FormikValues, FormikErrors } from 'formik';
import Button from '@material-ui/core/Button';
import AlertDefinitionForm from '../AlertDefinitionForm';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TranslationHelper from '../../../../helpers/TranslationHelper';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { useStyles } from '../../Themable.hooks';
import {
    IGroupAlertCondition,
    IParamAlertCondition,
    IEventAlertCondition,
    TAnyAlertCondition,
    ILayerAlertCondition,
} from '../../../../state/ui/forms';
import { alignmentDictionary } from '../../_utils/utils';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { IDialogProp } from '../../AlertSettingDialog';

const CONDITIONS_MAX_LENGTH = 4;

interface IOwnProps {
    errors?: FormikErrors<IGroupAlertCondition>;
    values: FormikValues;
    setFieldValue: (
        name: string,
        value:
            | IParamAlertCondition
            | IEventAlertCondition
            | ILayerAlertCondition
    ) => void;
    dialog: IDialogProp;
    name: string;
    type: number;
}

const GroupAlertCondition: React.FC<IOwnProps> = ({
    type,
    name,
    errors,
    values,
    setFieldValue,
    dialog,
}) => {
    const classes = useStyles();
    const [alignment, setAlignment] = useState<string>('left');
    useEffect(() => {
        setAlignment(alignmentDictionary[type]);
    }, []);
    const handleToggle = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string
    ) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
            setFieldValue(`${name}.type`, alignmentDictionary[newAlignment]);
        }
    };
    const { mode } = dialog;

    return (
        <>
            <Card className={classes.card}>
                <CardContent className={classes.card}>
                    <ToggleButtonGroup
                        className={classes.toggleButtons}
                        size="small"
                        value={alignment}
                        exclusive
                        onChange={handleToggle}
                    >
                        <ToggleButton
                            classes={{ selected: classes.selected }}
                            disabled={mode === 'edit' ? true : false}
                            value="left"
                        >
                            {TranslationHelper.translate('And')}
                        </ToggleButton>
                        <ToggleButton
                            classes={{ selected: classes.selected }}
                            disabled={mode === 'edit' ? true : false}
                            value="center"
                        >
                            {TranslationHelper.translate('Or')}
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <FieldArray
                        name={`${name}.conditions`}
                        validateOnChange={false}
                    >
                        {({ remove, push }) => {
                            return (
                                <>
                                    {values &&
                                        values.map(
                                            (
                                                condition: TAnyAlertCondition,
                                                index: number
                                            ) => (
                                                <div
                                                    className={
                                                        classes.condition
                                                    }
                                                    key={index}
                                                >
                                                    <AlertDefinitionForm
                                                        name={`${name}.conditions.${index}`}
                                                        type={condition.type}
                                                        values={values[index]}
                                                        errors={
                                                            errors
                                                                ?.conditions?.[
                                                                index
                                                            ]
                                                        }
                                                        setFieldValue={
                                                            setFieldValue
                                                        }
                                                        dialog={dialog}
                                                    />
                                                    {mode !== 'edit' &&
                                                        index !== 0 && (
                                                            <IconButton
                                                                size="small"
                                                                aria-label="close"
                                                                onClick={() => {
                                                                    remove(
                                                                        index
                                                                    );
                                                                }}
                                                            >
                                                                <CloseIcon fontSize="small" />
                                                            </IconButton>
                                                        )}
                                                </div>
                                            )
                                        )}
                                    {mode !== 'edit' && (
                                        <Button
                                            type="button"
                                            variant="contained"
                                            color="primary"
                                            disabled={
                                                values.length >=
                                                CONDITIONS_MAX_LENGTH
                                            }
                                            className={classes.addButton}
                                            onClick={() => {
                                                push({
                                                    type: '',
                                                    definitionId: '',
                                                    paramName: '',
                                                    paramValue: '',
                                                });
                                            }}
                                        >
                                            {TranslationHelper.translate(
                                                'Add condition'
                                            )}
                                        </Button>
                                    )}
                                </>
                            );
                        }}
                    </FieldArray>
                </CardContent>
            </Card>
        </>
    );
};
export default GroupAlertCondition;
