import React from 'react';
import { useStyles } from './Themable.hooks';

import { List, ListItem, ListItemText } from '@material-ui/core';

import TranslationHelper from '../../../../helpers/TranslationHelper';
import { IAlertContentDetail } from '../../../../services/alerts';
import { conditionTypes } from '../../../../state/app/alerts';
interface IOwnProps {
    contentDetail: IAlertContentDetail;
}

const getSuffix = (type: number) => {
    switch (type) {
        case conditionTypes.and:
            return TranslationHelper.translate(
                'detectAlert.complex.and.suffix'
            );
        case conditionTypes.or:
            return TranslationHelper.translate('detectAlert.complex.or.suffix');
        default:
            return '';
    }
};

const AlertDetails = ({ contentDetail }: IOwnProps) => {
    const classes = useStyles();

    const renderDetail = (
        data: IAlertContentDetail,
        suffix: string = '',
        key: string = ''
    ) => {
        const { children, content, conditionType } = data;

        const newSuffix = getSuffix(conditionType);
        const textSuffix = !children?.length ? suffix : '';

        return (
            <div key={'alertDetail' + key}>
                <ListItem className={classes.listItem}>
                    <ListItemText primary={content} />
                    <ListItemText primary={textSuffix.toUpperCase()} />
                </ListItem>
                {children && children.length > 0 && (
                    <>
                        <List className={classes.listNested}>
                            {children.map((el: IAlertContentDetail, index) =>
                                renderDetail(
                                    el,
                                    index < children.length - 1
                                        ? newSuffix
                                        : undefined,
                                    key + String(index)
                                )
                            )}
                        </List>
                        <ListItem className={classes.listItem}>
                            <ListItemText primary={suffix} />
                        </ListItem>
                    </>
                )}
            </div>
        );
    };

    return <List>{renderDetail(contentDetail)}</List>;
};

export default AlertDetails;
