import React, { useEffect, useRef, useState } from 'react';

import moment from 'moment';

import { useSnackbar } from 'notistack';

import Player from 'video.js/dist/types/player';

import {
    makeLinkVideoURL,
    makeVideoDownloadUrl,
} from '../../services/discovery/_shared/urlMakers';
import { fetchCustomSourceSet } from '../../services/discovery/fetchCustomSourceSet';

import { ISourceSet } from '../../state/types';
import { usePrivileges } from '../../state/auth/index.hooks';
import { setVideoTime, toggleVideoDialog } from '../../state/ui/videoUrl';
import {
    useVideoDialog,
    useVideoParams,
} from '../../state/ui/videoUrl/index.hooks';

import TranslationHelper from '../../helpers/TranslationHelper';
import TimeFormatter from '../../helpers/TimeFormatter';
import { getHlsParam } from '../../helpers/getHlsParam';
import { errorMessageHandler } from '../../helpers/errorMessageHandler';

import { useAppDispatch, useToolkitDispatch } from '../../hooks';

import {
    CAMERAS_RANGE,
    LABELS_MODEL_NOT_SELECTED,
} from '../../constants/videoPlayerSettings';
import { TOOLTIP_DEBOUNCE } from '../../constants/DebounceValues';

import VideoPlayerDialog from '../dialogs/VideoPlayerDialog/VideoPlayerDialog';
import DialogTitle from '../dialogs/DialogTitle/DialogTitle';
import CopyLinkContainer from '../../components/CopyLinkContainer';

import SelectField from '../SelectField';

interface IVideoUrlParams {
    sliderTimeRange: number[];
    paramName: string;
    anonymize: boolean;
    eventId: string;
    hls: boolean;
    labels: boolean;
    model?: string;
}

const CAMERAS = ['MOV1', 'MOV2', 'MOV3', 'MOV4'];

const LinkVideoPlayer = () => {
    const [copyVideoLinkTooltip, setCopyVideoLinkTooltip] = useState(
        TranslationHelper.translate('Create and copy to clipboard')
    );

    const [src, setSrc] = useState('');
    const [ids, setIds] = useState<string[]>([]);

    const [videoParams, setVideoParams] = useState<IVideoUrlParams>({
        sliderTimeRange: CAMERAS_RANGE,
        paramName: '',
        anonymize: false,
        eventId: '',
        hls: false,
        labels: false,
        model: LABELS_MODEL_NOT_SELECTED,
    });
    const privileges = usePrivileges();
    const dialog = useVideoDialog();
    const queryParams = useVideoParams();

    const { enqueueSnackbar } = useSnackbar();

    const videoRef = useRef<HTMLVideoElement | null>(null);
    const playerRef = React.useRef<Player | null>(null);

    const dispatch = useAppDispatch();

    const toolkitDispatch = useToolkitDispatch();

    useEffect(() => {
        if (queryParams) {
            const {
                timestamp,
                monitoredId,
                selectedCamera,
                rangeMinus,
                rangePlus,
                anonymize,
                labels,
                model,
            } = queryParams;
            const range = [
                rangeMinus ?? CAMERAS_RANGE[0],
                rangePlus ?? CAMERAS_RANGE[1],
            ];
            fetchCustomSourceSet({
                api: '/rest/api/source-sets/archive-events',
                label: 'Source',
                method: 'GET',
                params: {
                    from: timestamp,
                    hiddenParams: false,
                    monitoredId,
                    reportId: 'archiveEventsWithAttachments',
                    to: TimeFormatter.toLocalDate(
                        moment(timestamp).add(5, 'minute')
                    ),
                    withOrWithoutAttachments: true,
                },
            })
                .then((data: ISourceSet) => {
                    setVideoParams(
                        constructVideoParams(
                            data,
                            selectedCamera,
                            range,
                            anonymize,
                            labels,
                            model
                        )
                    );
                    setIds(getAvailableCamerasIds(data).sort());
                })
                .catch((e) => {
                    const message = errorMessageHandler(e.status)();
                    enqueueSnackbar(message, {
                        variant: 'error',
                    });
                });
        }
    }, [queryParams, dispatch]);

    useEffect(() => {
        if (videoParams.paramName) {
            const videoUrl = makeVideoDownloadUrl(videoParams).toString();
            setSrc(videoUrl);
        }
    }, [videoParams]);

    const constructVideoParams = (
        data: ISourceSet,
        camera: string,
        sliderTimeRange: number[],
        anonymize?: boolean,
        labels?: boolean,
        model?: string
    ) => {
        const event = Object.values(data.entities)[0].entities?.[0];

        const anonymizeParam = !!(anonymize && privileges.anonymize);
        const labelsParam = !!(labels && privileges.videoProcessingDebug);

        const videoUrlParams = {
            sliderTimeRange,
            paramName: camera,
            anonymize: anonymizeParam,
            hls: getHlsParam(anonymizeParam, labelsParam),
            eventId: event?.id || '',
            labels: labelsParam,
            model: labelsParam ? model : undefined,
        };
        return videoUrlParams;
    };

    if (!queryParams) {
        return null;
    }
    const getAvailableCamerasIds = (data: ISourceSet) => {
        return Object.keys(
            Object.values(data.entities)[0].entities?.[0] || {}
        ).filter((key) => CAMERAS.includes(key));
    };

    const changeCamera = (cameraName: string) => {
        setSrc('');
        const videoUrlParams = {
            ...videoParams,
            paramName: cameraName,
        };
        setVideoParams(videoUrlParams);
    };
    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
    };

    const copyVideoLink = () => {
        setCopyVideoLinkTooltip(
            TranslationHelper.translate('Link was created and copied!')
        );
        setTimeout(() =>
            copyToClipboard(
                makeLinkVideoURL({
                    ...queryParams,
                    selectedCamera: videoParams.paramName,
                }).toString()
            )
        );
        setTimeout(
            () =>
                setCopyVideoLinkTooltip(
                    TranslationHelper.translate('Create and copy to clipboard')
                ),
            TOOLTIP_DEBOUNCE
        );
    };

    const handleChangeCamera = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        toolkitDispatch(setVideoTime(videoRef.current?.currentTime));
        changeCamera(event.target.value);
    };

    const handleClose = (event?: object, reason?: string) => {
        if (reason === 'backdropClick') {
            return;
        }
        toolkitDispatch(toggleVideoDialog());
    };

    const renderDropdownButtons = () => {
        return (
            <CopyLinkContainer
                copyVideoLink={{
                    title: copyVideoLinkTooltip,
                    callback: copyVideoLink,
                }}
            />
        );
    };

    const renderTitleButton = () => {
        return (
            <SelectField
                name="selectedCamera"
                value={videoParams.paramName}
                ids={ids}
                handleChange={handleChangeCamera}
                translateContext={'mp'}
            />
        );
    };
    return (
        <>
            {src && (
                <VideoPlayerDialog
                    videoSrc={src}
                    videoRef={videoRef}
                    playerRef={playerRef}
                    open={dialog}
                    handleClose={handleClose}
                    type={
                        videoParams.hls ? 'application/x-mpegURL' : 'video/mp4'
                    }
                >
                    <DialogTitle
                        title={`XTrack Player:`}
                        close={handleClose}
                        dropDown={renderDropdownButtons}
                        titleButton={renderTitleButton}
                    />
                </VideoPlayerDialog>
            )}
        </>
    );
};

export default LinkVideoPlayer;
