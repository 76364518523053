import React from 'react';
import { useStyles } from '../FormsThemable.hooks';

import { MenuItem } from '@material-ui/core';
import { Field, FormikValues, FormikErrors } from 'formik';

import FieldWrapper from '../../../../components/common/formikWrappers/FieldWrapper';
import TranslationHelper from '../../../../helpers/TranslationHelper';
import { defaultConditionValue } from '../../_utils/utils';
import EventAlertDefinitionForm from '../EventAlertDefinitionForm';
import ParameterAlertDefinitionForm from '../ParameterAlertDefinitionForm';
import LayerAlertDefinitionForm from '../LayerAlertDefinitionForm';

import {
    IEventAlertCondition,
    ILayerAlertCondition,
    IParamAlertCondition,
    TAnyAlertCondition,
} from '../../../../state/ui/forms';
import { conditionTypes } from '../../../../state/app/alerts';
import GroupAlertCondition from '../GroupAlertCondition/GroupAlertCondition';
import { areLayersEnabled } from '../../../../constants/AppConfig';
import { IDialogProp } from '../../AlertSettingDialog';
import { useParamsDefinitions } from '../../../../state/app/alerts/index.hooks';

const TRIGGER_TYPES = [
    {
        key: 'triggerParameter',
        value: conditionTypes.param,
        label: 'Parameter',
    },
    {
        key: 'triggerEvent',
        value: conditionTypes.event,
        label: 'Event',
    },
    {
        key: 'triggerLayers',
        value: conditionTypes.layer,
        label: 'Layer',
    },
];

interface ITriggerType {
    key: string;
    value: number;
    label: string;
}

interface ITriggerEnabled {
    [key: number]: boolean | string;
}

interface IOwnProps {
    errors?: FormikErrors<TAnyAlertCondition>;
    setFieldValue: (
        name: string,
        value:
            | IParamAlertCondition
            | IEventAlertCondition
            | ILayerAlertCondition
    ) => void;
    values: FormikValues;
    dialog: IDialogProp;
    name: string;
    type: number | string;
}

const mapTriggerTypes = (
    triggerTypes: ITriggerType[],
    enabled: ITriggerEnabled,
    isInEditMode: boolean
) => {
    const triggerTypesToSort = [...triggerTypes];

    return triggerTypesToSort
        .sort((a, b) =>
            TranslationHelper.translate(a.label) >
            TranslationHelper.translate(b.label)
                ? 1
                : -1
        )
        .map((trigger) => {
            if (enabled[trigger.value] === false && !isInEditMode) {
                return null;
            }
            if (enabled[trigger.value] === 'disabled') {
                return (
                    <MenuItem
                        key={trigger.key}
                        value={trigger.value}
                        disabled={true}
                    >
                        {`${TranslationHelper.translate(trigger.label)} 
                        (${TranslationHelper.translate(
                            'No parameters available'
                        )})`}
                    </MenuItem>
                );
            }

            return (
                <MenuItem
                    key={trigger.key}
                    value={trigger.value}
                    disabled={enabled[trigger.value] === false}
                >
                    {TranslationHelper.translate(trigger.label)}
                </MenuItem>
            );
        });
};

const renderAlertForm = (
    type: number,
    dialog: IDialogProp,
    name: string,
    values: FormikValues,
    errors: FormikErrors<TAnyAlertCondition> | undefined,
    setFieldValue: (
        name: string,
        value:
            | IParamAlertCondition
            | IEventAlertCondition
            | ILayerAlertCondition
    ) => void
) => {
    const { mode } = dialog;
    switch (type) {
        case conditionTypes.and:
        case conditionTypes.or:
            return (
                <GroupAlertCondition
                    dialog={dialog}
                    name={name}
                    type={values.type}
                    values={values.conditions}
                    errors={errors}
                    setFieldValue={setFieldValue}
                />
            );
        case conditionTypes.param:
            return (
                <ParameterAlertDefinitionForm
                    dialog={dialog}
                    name={name}
                    values={values}
                    errors={errors}
                    setFieldValue={setFieldValue}
                />
            );
        case conditionTypes.event:
            return (
                <EventAlertDefinitionForm
                    mode={mode}
                    name={name}
                    values={values}
                    errors={errors}
                />
            );
        case conditionTypes.layer:
            return (
                <LayerAlertDefinitionForm
                    dialog={dialog}
                    name={name}
                    values={values}
                    errors={errors}
                    setFieldValue={setFieldValue}
                />
            );
        default:
            return null;
    }
};
const AlertDefinitionForm = ({
    dialog,
    name,
    type,
    ...inputProps
}: IOwnProps) => {
    const classes = useStyles({});

    const inEditMode = dialog.mode === 'edit';

    const layersEnabled = areLayersEnabled()?.layers;

    const { values, errors, setFieldValue } = inputProps;

    const paramsDefinitions = useParamsDefinitions();

    const handleChange = (value: number) => {
        setFieldValue(`${name}`, defaultConditionValue[value]);
    };

    return (
        <div className={classes.card}>
            {values.type !== (conditionTypes.and || conditionTypes.or) && (
                <Field
                    className={classes.field}
                    error={!!errors?.type}
                    helperText={
                        errors?.type &&
                        TranslationHelper.translate(errors?.type)
                    }
                    disabled={inEditMode}
                    name={`${name}.type`}
                    label={TranslationHelper.translate('Trigger')}
                    select={true}
                    component={FieldWrapper}
                    required={true}
                    afterOnChange={handleChange}
                    defaultValue={conditionTypes.param}
                    variant="outlined"
                >
                    {mapTriggerTypes(
                        TRIGGER_TYPES,
                        {
                            [conditionTypes.layer]: !!layersEnabled,
                            [conditionTypes.param]: !paramsDefinitions.length
                                ? 'disabled'
                                : '',
                        },
                        inEditMode
                    )}
                </Field>
            )}

            {renderAlertForm(
                Number(type),
                dialog,
                name,
                values,
                errors,
                setFieldValue
            )}
        </div>
    );
};

export default AlertDefinitionForm;
