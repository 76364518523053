import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
    createStyles({
        dialogContent: {
            display: 'flex',
            minWidth: '300px',
            minHeight: '300px',
            justifyContent: 'center',
        },
        card: {
            display: 'flex',
            flexDirection: 'column',
        },
        cardLoading: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100px',
        },
        cardConent: {
            display: 'flex',
            flexDirection: 'column',
        },
        condition: {
            display: 'flex',
            flexDirection: 'row',
        },
        setupField: {
            marginBottom: '18px',
            width: '100%',
        },
        setupFields: {
            display: 'block',
            marginBottom: '12px',
        },
        addButton: {
            margin: '6px',
            width: '200px',
        },
        toggleButtons: {
            margin: '0 0 12px 6px',
        },
        selected: {
            fontWeight: 'bold',
            border: '1px solid black',
        },
    })
);
