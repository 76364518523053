import React, { useEffect } from 'react';

import { CircularProgress } from '@mui/material';

import {
    useLabelModels,
    useModelsStatus,
} from '../../../../../../../../../state/app/filesSlice/index.hooks';
import { fetchLabelsModels } from '../../../../../../../../../state/app/filesSlice';

import { LABELS_MODEL_NOT_SELECTED } from '../../../../../../../../../constants/videoPlayerSettings';

import SelectField from '../../../../../../../../../components/SelectField';

import { useToolkitDispatch } from '../../../../../../../../../hooks';

interface IOwnProps {
    handleChange: any;
    selectedModel?: string;
}

export const SelectLabelsModels = ({
    handleChange,
    selectedModel,
}: IOwnProps) => {
    const models = useLabelModels();
    const status = useModelsStatus();

    const toolkitDispatch = useToolkitDispatch();

    useEffect(() => {
        if (status === 'idle') {
            toolkitDispatch(fetchLabelsModels());
        }
    }, []);

    const options = [LABELS_MODEL_NOT_SELECTED, ...(models ?? [])];

    if (status === 'loading') {
        return <CircularProgress sx={{ color: 'white' }} />;
    }
    return (
        <SelectField
            label="Labels"
            name="labels"
            value={selectedModel || LABELS_MODEL_NOT_SELECTED}
            ids={options}
            handleChange={handleChange}
        />
    );
};
