import {
    createSlice,
    createSelector,
    createAsyncThunk,
} from '@reduxjs/toolkit';

import moment from 'moment';

import {
    restGetTask,
    restGetTasks,
} from '../../../../../services/customerService/tasks';
import { ISourceSet } from '../../../../types';
import {
    IServiceTask,
    IServiceTasksUrlParams,
    ITaskPreFetchParams,
} from '../../../../../models/customerService';

import { getSourceSetWithFilter } from '../../../../../pages/discovery/selectors/sourceSet/getSourceSetWithFilter';

import { TRootState } from '../../../../../store';
import { getMappedDictionaries } from '../../../../../state/app/dictionaries';
import TranslationHelper from '../../../../../helpers/TranslationHelper';
import { CSC_TASK_STATUS } from '../../../../../constants/dictionaries/CscTaskStatus';
import { getValidRfidFilter } from '../../clientsAndLocations';

interface IState {
    selectedTaskId: number | null;
    task: IServiceTask | null;
    tasks: IGroupedTasks[] | null;
    loading: boolean;
    paneLoading: boolean;
    onlyNotAssigned: boolean;
    onlyNotPlanned: boolean;
    dates: {
        from: string;
        to: string;
    };
    openSectionTasks: boolean;
    openSectionTaskAttachments: boolean;
}

interface IGroupedTasks {
    locationName: string;
    clientName: string;
    locationId: number;
    clientId: string;
    coordinate: { x: number; y: number };
    values: IServiceTask[];
}
export const initialState: IState = {
    tasks: null,
    selectedTaskId: null,
    task: null,
    loading: false,
    paneLoading: false,
    onlyNotAssigned: false,
    onlyNotPlanned: false,
    openSectionTasks: false,
    openSectionTaskAttachments: false,
    dates: {
        from: moment(new Date()).format('YYYY-MM-DD'),
        to: moment(new Date()).format('YYYY-MM-DD'),
    },
};
export const TASKS_GRID_ID = 'Tasks';

export const fetchTasks = createAsyncThunk(
    'get:ui/customerService/bottomGrid/tasks',
    async (params: ITaskPreFetchParams, { rejectWithValue }) => {
        const locations = params.selectedClientsAndLocations.filter(
            (item) => item.id
        );
        const responses = await Promise.all(
            locations.map(async (location) => {
                const updatedParams = {
                    ...params,
                    locationId: Number(location.id),
                    clientId: null,
                    selectedClientsAndLocations: null,
                } as IServiceTasksUrlParams;
                const getValues = async () => {
                    try {
                        const response = await restGetTasks(updatedParams);
                        return response;
                    } catch (e: any) {
                        throw rejectWithValue({
                            status: e.status,
                            errorMsg: e.responseJSON.message,
                        });
                    }
                };

                const values = await getValues();
                return {
                    clientName: location.clientName,
                    locationName: location.name,
                    clientId: location.clientId,
                    locationId: Number(location.id),
                    coordinate: location.coordinates,
                    values,
                };
            })
        );
        return responses;
    }
);

export const fetchTask = createAsyncThunk(
    'get:ui/customerService/clientsAndLocations/tasks/id',
    async (id: number) => {
        const response = await restGetTask(id);

        return response;
    }
);

const tasks = createSlice({
    name: 'tasks',
    initialState,
    reducers: {
        selectTask(state, action) {
            state.selectedTaskId = action.payload;
        },
        toggleOnlyNotAssigned(state, action) {
            state.onlyNotAssigned = action.payload;
        },
        toggleOnlyNotPlanned(state, action) {
            state.onlyNotPlanned = action.payload;
        },
        toggleCollapseTasks(state) {
            state.openSectionTasks = !state.openSectionTasks;
        },
        toggleCollapseTaskAttachments(state) {
            state.openSectionTaskAttachments =
                !state.openSectionTaskAttachments;
        },
        setDates(state, action) {
            state.dates = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTasks.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(fetchTasks.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchTasks.fulfilled, (state, action) => {
            state.tasks = action.payload;
            state.loading = false;
        });
        builder.addCase(fetchTask.pending, (state) => {
            state.task = null;
            state.paneLoading = true;
        });
        builder.addCase(fetchTask.fulfilled, (state, action) => {
            state.task = action.payload;
            state.paneLoading = false;
        });
    },
});

export const getTasks = (state: TRootState) =>
    state.ui.customerService.tasks.tasks;

export const getTask = (state: TRootState) =>
    state.ui.customerService.tasks.task;

export const getSelectedTaskId = (state: TRootState) =>
    state.ui.customerService.tasks.selectedTaskId;

export const getTasksLoading = (state: TRootState) =>
    state.ui.customerService.tasks.loading;

export const getTaskPaneLoading = (state: TRootState) =>
    state.ui.customerService.tasks.paneLoading;

export const getSourceSetModels = (state: TRootState) =>
    state.ui.discovery.general.sourceSetModels;
export const getTasksOnlyNotAssigned = (state: TRootState) =>
    state.ui.customerService.tasks.onlyNotAssigned;

export const getTasksOnlyNotPlanned = (state: TRootState) =>
    state.ui.customerService.tasks.onlyNotPlanned;
export const getTaskDates = (state: TRootState) =>
    state.ui.customerService.tasks.dates;

export const getOpenSectionTasks = (state: TRootState) =>
    state.ui.customerService.tasks.openSectionTasks;
export const getOpenSectionTaskAttachments = (state: TRootState) =>
    state.ui.customerService.tasks.openSectionTaskAttachments;
export const getTasksSourceSetModel = createSelector(
    [getSourceSetModels],
    (models) => models?.tasks || null
);

const leadingZerosPattern = /^0+/;

const filterByRfid = (rfid: string | null) => (task: IServiceTask) =>
    rfid === null ||
    rfid
        .replace(leadingZerosPattern, '')
        .localeCompare(
            task.rfidCode?.replace(leadingZerosPattern, '') || '',
            undefined,
            {
                sensitivity: 'accent',
            }
        ) == 0;

export const getTasksAsSourceSet = createSelector(
    [getTasks, getMappedDictionaries, getValidRfidFilter],
    (tasks, dictionaries, rfidFilter): ISourceSet | null =>
        tasks && {
            id: 'tasks',
            definitionId: TASKS_GRID_ID,
            label: 'Tasks',
            attributes: [
                {
                    id: 'tasks',
                    type: 'parent',
                    label: 'Task',
                    selectable: true,
                    children: [
                        {
                            id: 'status',
                            label: 'Status',
                            type: 'text',
                        },
                        {
                            id: 'clientName',
                            label: 'Client',
                            type: 'text',
                        },
                        {
                            id: 'locationName',
                            label: 'Location name',
                            type: 'text',
                        },
                        {
                            id: 'plannedOn',
                            label: 'Planned on',
                            type: 'date',
                            formatting: {
                                pattern: 'short',
                            },
                        },
                        {
                            id: 'statusDate',
                            label: 'Status date',
                            type: 'date',
                        },
                        {
                            id: 'vehicleName',
                            label: 'Vehicle name',
                            type: 'text',
                        },
                        {
                            id: 'employeeName',
                            label: 'Employee name',
                            type: 'text',
                        },
                        {
                            id: 'activityCategoryId',
                            label: 'Type 1',
                            type: 'text',
                        },
                        {
                            id: 'serviceTypeId',
                            label: 'Type 2',
                            type: 'text',
                        },
                        {
                            id: 'objectCategoryId',
                            label: 'Type 3',
                            type: 'text',
                        },
                        {
                            id: 'serviceClassId',
                            label: 'Type 4',
                            type: 'text',
                        },
                        {
                            id: 'unitCount',
                            label: 'Units count',
                            type: 'number',
                        },
                        { id: 'rfidCode', label: 'RFID', type: 'text' },
                        { id: 'notice', label: 'Notice', type: 'text' },
                        {
                            id: 'externalNumber',
                            label: 'External number',
                            type: 'text',
                        },
                        {
                            id: 'hasAttachments',
                            label: 'Attachments',
                            type: 'iconAttachment',
                            cellClass: 'booleanType',
                        },
                    ],
                },
                {
                    id: 'contractItems',
                    type: 'parent',
                    label: 'Contract item',
                    children: [
                        {
                            id: 'startDate',
                            label: 'Start date',
                            type: 'date',
                            formatting: {
                                pattern: 'short',
                            },
                        },
                        {
                            id: 'stopDate',
                            label: 'Stop date',
                            type: 'date',
                            formatting: {
                                pattern: 'short',
                            },
                        },
                        {
                            id: 'nextOccurrenceDate',
                            label: 'Next occurrence date',
                            type: 'date',
                            formatting: {
                                pattern: 'short',
                            },
                        },
                        {
                            id: 'cycleDefinition',
                            label: 'Cycle definition',
                            type: 'text',
                        },

                        {
                            id: 'assetsCount',
                            label: 'Assets count',
                            type: 'number',
                        },
                        {
                            id: 'contractNotice',
                            label: 'Notice',
                            type: 'text',
                        },
                        {
                            id: 'contractExternalNumber',
                            label: 'External number',
                            type: 'text',
                        },
                        {
                            id: 'hasAttachments',
                            label: 'Attachments',
                            type: 'iconAttachment',
                            cellClass: 'booleanType',
                        },
                    ],
                },
                {
                    id: 'contract',
                    type: 'parent',
                    label: 'Contract',
                    children: [
                        {
                            id: 'name',
                            label: 'Name',
                            type: 'text',
                        },
                        {
                            id: 'contractStartDate',
                            label: 'Start date',
                            type: 'date',
                            formatting: {
                                pattern: 'short',
                            },
                        },
                        {
                            id: 'contractStopDate',
                            label: 'Stop date',
                            type: 'date',
                            formatting: {
                                pattern: 'short',
                            },
                        },
                        {
                            id: 'isActive',
                            label: 'Is active',
                            type: 'statusIcon',
                            cellClass: 'booleanType',
                        },
                    ],
                },
            ],
            layersAttributes: [],
            _meta: {},
            entities: tasks
                .map((task: IGroupedTasks): any => {
                    return task.values
                        .filter(filterByRfid(rfidFilter))
                        .map((taskValue: IServiceTask): any => {
                            const {
                                assetsCount,
                                startDate,
                                stopDate,
                                nextOccurrenceDate,
                                cycleDefinition,
                                notice: contractNotice,
                                externalNumber: contractExternalNumber,
                            } = taskValue.contractItem || {};
                            const {
                                name,
                                startDate: contractStartDate,
                                stopDate: contractStopDate,
                                isActive,
                            } = (taskValue.contractItem || { contract: {} })
                                .contract;
                            return {
                                ...taskValue,
                                rfidCode: taskValue.rfidCode?.toUpperCase(),
                                activityCategoryId:
                                    dictionaries['activity-category']?.[
                                        taskValue.activityCategoryId
                                    ]?.name,
                                serviceTypeId:
                                    dictionaries['service-type']?.[
                                        taskValue.serviceTypeId
                                    ]?.name,
                                objectCategoryId:
                                    dictionaries['object-category']?.[
                                        taskValue.objectCategoryId
                                    ]?.name,
                                serviceClassId:
                                    dictionaries['service-class']?.[
                                        taskValue?.serviceClassId || ''
                                    ]?.name,
                                status: TranslationHelper.translate(
                                    CSC_TASK_STATUS[taskValue.status]
                                ),
                                statusId: taskValue.status,
                                locationId: task.locationId,
                                clientId: task.clientId,
                                clientName: task.clientName,
                                locationName: task.locationName,
                                coordinate: task.coordinate,
                                assetsCount,
                                startDate,
                                stopDate,
                                nextOccurrenceDate,
                                cycleDefinition,
                                contractNotice,
                                contractExternalNumber,
                                name,
                                contractStartDate,
                                contractStopDate,
                                isActive,
                                _meta: {},
                            };
                        });
                })
                .flat(),
        }
);

export const getTasksSourceSetWithFilter = getSourceSetWithFilter(
    getTasksSourceSetModel,
    getTasksAsSourceSet
);

export const {
    toggleOnlyNotAssigned,
    toggleOnlyNotPlanned,
    setDates,
    selectTask,
    toggleCollapseTaskAttachments,
    toggleCollapseTasks,
} = tasks.actions;

export default tasks.reducer;
