import React, { ChangeEvent } from 'react';

import { IconButton, Switch, Tooltip } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/GetApp';
import PlayIcon from '@material-ui/icons/PlayCircleOutline';
import { default as GridIcon } from '@material-ui/icons/GridOn';

import { usePrivileges } from '../../../../../../../../../state/auth/index.hooks';

import TranslationHelper from '../../../../../../../../../helpers/TranslationHelper';

import { LABELS_MODEL_NOT_SELECTED } from '../../../../../../../../../constants/videoPlayerSettings';

import { usePreviewAction } from '../../../../../../../../../pages/discovery/selectors/index.hooks';
import { SelectLabelsModels } from './SelectLabelsModels';
import { IPlayerOptions } from '../../ShowImage/ShowImage';

interface IOwnProps {
    getMovieDownloadUrl: () => string;
    setShowVideoPlayerActions: (bool: boolean) => void;
    showPlayer: boolean;
    handlePlayerOpen: () => void;
    handleOpenDetails: (anonymize: boolean) => void;
    handleChangeAnonymize: (checked: boolean) => void;
    anonymize: boolean;
    playerOptions: IPlayerOptions;
    handleChangePlayerOptions: (options: IPlayerOptions) => void;
    classes: any;
}

type TProps = IOwnProps;

const PlayerActionsContainer = ({
    classes,
    getMovieDownloadUrl,
    setShowVideoPlayerActions,
    showPlayer,
    handlePlayerOpen,
    handleOpenDetails,
    handleChangeAnonymize,
    anonymize,
    playerOptions,
    handleChangePlayerOptions,
}: TProps) => {
    const privileges = usePrivileges();
    const previewAction = usePreviewAction();

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        handleChangePlayerOptions({
            ...playerOptions,
            labels: value === LABELS_MODEL_NOT_SELECTED ? undefined : true,
            model: value,
        });
    };

    return (
        <div className={classes.actionsContainer}>
            <a
                href={getMovieDownloadUrl()}
                download={true}
                onClick={() => setShowVideoPlayerActions(false)}
            >
                <Tooltip title={TranslationHelper.translate('Download')}>
                    <IconButton
                        className={
                            showPlayer ? classes.buttonPlay : classes.button
                        }
                    >
                        <DownloadIcon />
                    </IconButton>
                </Tooltip>
            </a>
            {showPlayer && (
                <Tooltip title={`${TranslationHelper.translate('Play')}`}>
                    <IconButton
                        className={classes.buttonPlay}
                        onClick={handlePlayerOpen}
                    >
                        <PlayIcon />
                    </IconButton>
                </Tooltip>
            )}
            {!previewAction?._webx?.changeSourceSetDisabled && (
                <Tooltip title={TranslationHelper.translate('Show details')}>
                    <IconButton
                        className={
                            showPlayer ? classes.buttonPlay : classes.button
                        }
                        onClick={() => handleOpenDetails(anonymize)}
                    >
                        <GridIcon />
                    </IconButton>
                </Tooltip>
            )}
            {privileges.anonymizeSwitchVisible && (
                <>
                    <Tooltip title={TranslationHelper.translate('Anonymize')}>
                        <Switch
                            classes={{
                                track: classes.switchTrack,
                                colorPrimary: classes.switchColorPrimary,
                                switchBase: classes.switchBase,
                            }}
                            color="primary"
                            onChange={(e, checked) =>
                                handleChangeAnonymize(checked)
                            }
                            checked={anonymize}
                        />
                    </Tooltip>
                </>
            )}
            {privileges.videoProcessingDebug && (
                <SelectLabelsModels
                    handleChange={handleChange}
                    selectedModel={playerOptions.model}
                />
            )}
        </div>
    );
};

export default PlayerActionsContainer;
