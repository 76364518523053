import React, { useEffect } from 'react';
import { Form, FormikErrors, FormikValues } from 'formik';

import Alert from '@material-ui/lab/Alert';

import { useStyles } from '../Themable.hooks';
import { useLanguages } from '../Connectable.hooks';
import {
    IAlertForm,
    IEventAlertCondition,
    ILayerAlertCondition,
    IParamAlertCondition,
    TAnyAlertCondition,
} from '../../../state/ui/forms';

import AlertBaseInfo from './AlertBaseInfo';
import TranslationHelper from '../../../helpers/TranslationHelper';
import {
    conditionTypes,
    fetchParamsDefinitions,
    setParamsLoading,
} from '../../../state/app/alerts';
import { areLayersEnabled } from '../../../constants/AppConfig';
import { IDialogProp } from '../AlertSettingDialog';
import { useAppDispatch } from '../../../hooks';
import AlertConditions from './AlertConditions';

interface IOwnProps {
    errors: FormikErrors<IAlertForm>;
    values: FormikValues;
    setFieldValue: (
        name: string,
        value:
            | IParamAlertCondition
            | IEventAlertCondition
            | ILayerAlertCondition
    ) => void;
    dialog: IDialogProp;
    displaySubjectFields: boolean;
}

const containsLayerCondition = (conditions: TAnyAlertCondition[] = []) => {
    return conditions.some((el) => el.type === conditionTypes.layer);
};

const AlertForm = ({
    errors,
    values,
    setFieldValue,
    dialog,
    displaySubjectFields,
}: IOwnProps) => {
    const classes = useStyles();
    const languages = useLanguages();
    const inEditMode = dialog.mode === 'edit';
    const dispatch = useAppDispatch();

    const layersEnabled = areLayersEnabled()?.layers;

    useEffect(() => {
        if (values.object || values.groups.length) {
            dispatch(setParamsLoading({ loading: true }));
            dispatch(
                fetchParamsDefinitions(values.object ? values.object.id : '')
            );
        }
    }, [values.object, values.groups]);
    return (
        <Form>
            {inEditMode &&
                !!layersEnabled === false &&
                containsLayerCondition(values.condition.conditions) && (
                    <Alert severity="warning">
                        {TranslationHelper.translate(
                            "Alert detection for layers is currently switched off. Alerts won't be detected."
                        )}
                    </Alert>
                )}
            <div className={classes.setupFields}>
                <AlertBaseInfo
                    values={values}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    setupFieldClass={classes.setupField}
                    languages={languages}
                    inEditMode={inEditMode}
                    displaySubjectFields={displaySubjectFields}
                />
            </div>
            <AlertConditions
                values={values}
                errors={errors}
                setFieldValue={setFieldValue}
                dialog={dialog}
                displaySubjectFields={displaySubjectFields}
            />
        </Form>
    );
};
export default AlertForm;
